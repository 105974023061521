::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: rgba(144, 144, 203, 0.1);
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(188, 188, 224, 1);
  border-radius: 20px;
}

:root {
  body {
    touch-action: pan-x pan-y;
  }
  a {
    text-decoration: none;
  }

  *:focus-visible {
    outline: none;
  }

  ul[aria-label='Select hours'] {
    &:after {
      content: none;
    }
  }

  ul[aria-label='Select meridiem'] {
    &:after {
      content: none;
    }
  }

  ul[aria-label='Select minutes'] {
    &:after {
      content: none;
    }
  }
}
